import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CanadaIndexPage = () => {
  return (
    <Layout>
      <Seo title="Canada" />
      <main className="l-main">
        <div className="l-constrained text-highlight">
          <h1>Canada</h1>
          <ul>
            <li><Link to="/canada/health/">Health</Link></li>
            <li>Covid19
              <ul>
                <li><Link to="/canada/covid19/">Canada</Link></li>
                <li><Link to="/canada/covid19/ab/">Alberta</Link></li>
                <li><Link to="/canada/covid19/bc/">British Columbia</Link></li>
                <li><Link to="/canada/covid19/mb/">Manitoba</Link></li>
                <li><Link to="/canada/covid19/nb/">New Brunswick</Link></li>
                <li><Link to="/canada/covid19/nl/">Newfoundland and Labrador</Link></li>
                <li><Link to="/canada/covid19/nt/">Northwest Territories</Link></li>
                <li><Link to="/canada/covid19/ns/">Nova Scotia</Link></li>
                <li><Link to="/canada/covid19/nu/">Nunavut</Link></li>
                <li><Link to="/canada/covid19/on/">Ontario</Link></li>
                <li><Link to="/canada/covid19/pe/">Prince Edward Island</Link></li>
                <li><Link to="/canada/covid19/qc/">Quebec</Link></li>
                <li><Link to="/canada/covid19/sk/">Saskatchewan</Link></li>
                <li><Link to="/canada/covid19/yt/">Yukon Territory</Link></li>
              </ul>
            </li>
            <li>Cities
              <ul>
                <li><Link to="/canada/toronto/">Toronto</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </main>
    </Layout>
  )
}

export default CanadaIndexPage
